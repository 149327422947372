@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');

html {
  height: 100vh;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: 'Roboto', 'DM Sans', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Roboto', 'DM Sans', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
